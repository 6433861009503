<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.setting_notice') }}
    </template>
    <template #contentBody>
      <a-row :gutter="[32, 32]" type="flex" justify="center">
        <a-col flex="0 0 600px">
          <a-spin :spinning="state.smsLoading">
            <a-card size="small" hoverable>
              <template #title>
                <span style="font-weight: bold;">{{ $t('user.sms_notification') }}</span>
                <a-popover placement="topLeft">
                  <template #content>
                    <div style="max-width: 350px;">
                      {{ $t('user.sms_notification_tips') }}<span v-if="state.configInfo.managerPhoneNumber">: {{ state.configInfo.managerUserPhoneCountryCode }} {{ state.configInfo.managerPhoneNumber }}</span>
                    </div>
                  </template>
                  <InfoCircleFilled class="pl-1 text-primary" />
                </a-popover>
                <span v-if="state.configInfo.phone" class="pl-3" style="color: #acacac;">{{ state.configInfo.phoneCountryCode }} {{ state.configInfo.phone }}</span>
              </template>
              <template #extra>
                <a-switch v-model:checked="state.configInfo.isEnablePhone" :disabled="!(state.configInfo.managerPhoneNumber || state.configInfo.phone)" @change="handleChangeSmsActive()" />
              </template>
              <a-list size="small">
                <a-list-item v-for="(item, index) in state.sceneList" :key="index">
                  <a-row style="width: 100%;" type="flex" justify="space-between">
                    <a-col>
                      {{ $t($enumLangkey('notificationBusinessType', item.notificationBusinessType)) }}
                      <span v-if="item.phone" class="pl-1" style="color: #acacac;">{{ item.phoneCountryCode }} {{ item.phone }}</span>
                    </a-col>
                    <a-col>
                      <a-switch v-model:checked="item.isEnablePhone" :disabled="!state.configInfo.isEnablePhone" @click="handleChangeItemSmsActive(item)" />
                    </a-col>
                  </a-row>
                </a-list-item>
              </a-list>
            </a-card>
          </a-spin>
        </a-col>
        <a-col flex="0 0 600px">
          <a-spin :spinning="state.mailLoading">
            <a-card size="small" hoverable>
              <template #title>
                <span style="font-weight: bold;">{{ $t('user.email_notification') }}</span>
                <a-popover>
                  <template #content>
                    <div style="max-width: 350px;">
                      {{ $t('user.email_notification_tips') }}<span v-if="state.configInfo.managerUserEmail">: {{ state.configInfo.managerUserEmail }}</span>
                    </div>
                  </template>
                  <InfoCircleFilled class="pl-1 text-primary" />
                </a-popover>
                <span class="pl-3" style="color: #acacac;">{{ state.configInfo.mail ? state.configInfo.mail : $t('user.mailbox_not_configured') }}</span>
                <FormOutlined class="ml-1" style="color: #acacac;" @click="editMailModalState.visible = true;" />
              </template>
              <template #extra>
                <a-switch v-model:checked="state.configInfo.isEnableMail" :disabled="!(state.configInfo.managerUserEmail || state.configInfo.mail)" @change="handleChangeMailActive()" />
              </template>
              <a-list size="small">
                <a-list-item v-for="(item, index) in state.sceneList" :key="index">
                  <a-row style="width: 100%;" type="flex" justify="space-between">
                    <a-col>
                      {{ $t($enumLangkey('notificationBusinessType', item.notificationBusinessType)) }}
                      <span v-if="item.mail" class="pl-1" style="color: #acacac;">{{ item.mail }}</span>
                    </a-col>
                    <a-col>
                      <a-switch v-model:checked="item.isEnableMail" :disabled="!state.configInfo.isEnableMail" @click="handleChangeItemMailActive(item)" />
                    </a-col>
                  </a-row>
                </a-list-item>
              </a-list>
            </a-card>
          </a-spin>
        </a-col>
      </a-row>

      <EditMailModal v-model:visible="editMailModalState.visible" :mail-type="1" @confirm="init" />
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, onMounted } from 'vue'
import { Card, Col, List, message, Popover, Row, Spin, Switch } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import EditMailModal from './components/EditMailModal.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { setFormStateValue } from '@/utils/general.js';
import {
  getByShopIdWithUser,
  getSceneList,
  isActiveSms,
  isActiveMail,
  isActiveItemSms,
  isActiveItemMail,
} from '@/api/modules/setting/notice.js';

export default defineComponent({
  name: "setting_notice",
  components: {
    ASpin: Spin,
    ACard: Card,
    ASwitch: Switch,
    AList: List,
    AListItem: List.Item,
    ARow: Row,
    ACol: Col,
    APopover: Popover,
    Content,
    EditMailModal,
  },
  setup () {
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      smsLoading: false,
      mailLoading: false,
      configInfo: {
        // 主账号安全电话&邮箱
        managerUserPhoneCountryCode: null,
        managerPhoneNumber: null,
        managerUserEmail: null,
        // 通知主电话&邮箱
        phoneCountryCode: null,
        phone: null,
        isEnablePhone: false,
        mail: null,
        isEnableMail: false,
      },
      sceneList: [],
    })

    const editMailModalState = reactive({
      visible: false,
    })

    // 改变短信通知总开关
    const handleChangeSmsActive = () => {
      state.smsLoading = true;
      isActiveSms({ isActive: state.configInfo.isEnablePhone })
        .then(() => {
          message.success(vueI18n.t('common.succeed'));
        }).catch(() => {
          state.configInfo.isEnablePhone = !state.configInfo.isEnablePhone;
        }).finally(() => {
          state.smsLoading = false;
        });
    }

    // 改变邮件通知总开关
    const handleChangeMailActive = () => {
      state.mailLoading = true;
      isActiveMail({ isActive: state.configInfo.isEnableMail })
        .then(() => {
          message.success(vueI18n.t('common.succeed'));
        }).catch(() => {
          state.configInfo.isEnableMail = !state.configInfo.isEnableMail;
        }).finally(() => {
          state.mailLoading = false;
        });
    }

    // 改变场景短信通知开关
    const handleChangeItemSmsActive = (item) => {
      state.smsLoading = true;
      const data = {
        isActive: item.isEnablePhone,
        notificationBusinessType: item.notificationBusinessType,
      }
      isActiveItemSms(data)
        .then(() => {
          message.success(vueI18n.t('common.succeed'));
        }).catch(() => {
          item.isEnablePhone = !item.isEnablePhone;
        }).finally(() => {
          state.smsLoading = false;
        });
    }

    // 改变场景邮件通知开关
    const handleChangeItemMailActive = (item) => {
      state.mailLoading = true;
      const data = {
        isActive: item.isEnableMail,
        notificationBusinessType: item.notificationBusinessType,
      }
      isActiveItemMail(data)
        .then(() => {
          message.success(vueI18n.t('common.succeed'));
        }).catch(() => {
          item.isEnableMail = !item.isEnableMail;
        }).finally(() => {
          state.mailLoading = false;
        });
    }

    const init = async () => {
      try {
        state.smsLoading = true;
        state.mailLoading = true;

        let userInfo = getters.userInfo;
        
        state.configInfo.managerUserPhoneCountryCode = userInfo.managerUserPhoneCountryCode;
        state.configInfo.managerPhoneNumber = userInfo.managerPhoneNumber;
        state.configInfo.managerUserEmail = userInfo.managerUserEmail;

        let { result: config } = await getByShopIdWithUser();
        if (config) {
          setFormStateValue(state.configInfo, config);
        }
        let { result: sceneList } = await getSceneList();
        if (Array.isArray(sceneList)) {
          state.sceneList = sceneList;
        } else {
          state.sceneList = [];
        }
      } catch (error) {
      } finally {
        state.smsLoading = false;
        state.mailLoading = false;
      }
    }

    onMounted(init);

    return {
      state,
      editMailModalState,
      handleChangeSmsActive,
      handleChangeMailActive,
      handleChangeItemSmsActive,
      handleChangeItemMailActive,
      init,
    }
  }
})
</script>

<style scoped>

</style>