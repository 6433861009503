import client from "@/api/client";

const api = {
  // 获取单个店铺通知配置信息
  getByShopIdWithUser: "/api/ProductService/ShopNotificationConfig/GetByShopIdWithUser",
  // 获取店铺业务通知列表
  getSceneList: "/api/ProductService/ShopNotificationBusinessConfig/GetSceneList",
  // 开启/关闭短信通知
  isActiveSms: "/api/ProductService/ShopNotificationConfig/IsActiveSms",
  // 开启/关闭邮件通知
  isActiveMail: "/api/ProductService/ShopNotificationConfig/IsActiveMail",
  // 开启/关闭场景短信通知
  isActiveItemSms: "/api/ProductService/ShopNotificationBusinessConfig/IsActiveSms",
  // 开启/关闭场景邮件通知
  isActiveItemMail: "/api/ProductService/ShopNotificationBusinessConfig/IsActiveMail",
  
  // 用户更改通知邮箱获取验证码
  getUpdateNotificationMailValidCode: "/api/ProductService/ValidCode/UpdateNotificationMailValidCode",
  // 店铺通知配置更改邮箱(主)
  updateShopNotificationConfigMail: "/api/ProductService/ShopNotificationConfig/UpdateMail",
  // 用户更改安全邮箱获取验证码
  getUpdateUserMailValidCode: "/api/ProductService/ValidCode/UpdateUserMailValidCode",
  // 获取更改安全邮箱获取短信验证码
  getUpdateUserMailSmsValidCode: "/api/ProductService/ValidCode/UpdateUserMailSmsValidCode",
  // 验证短信验证码是否正确并返回校验码
  checkUpdateUserMailSmsValidCode: "/api/ProductService/Account/CheckUpdateUserMailSmsValidCode",
  // 用户更改安全邮箱
  updateAccountMail: "/api/ProductService/Account/UpdateMail",
}

export const getByShopIdWithUser = (params) => {
  return client.request({
    url: api.getByShopIdWithUser,
    params,
    method: "GET",
  });
};

export const getSceneList = (data) => {
  return client.request({
    url: api.getSceneList,
    data,
    method: "POST",
  });
};

export const isActiveSms = (data) => {
  return client.request({
    url: api.isActiveSms,
    data,
    method: "POST",
  });
};

export const isActiveMail = (data) => {
  return client.request({
    url: api.isActiveMail,
    data,
    method: "POST",
  });
};

export const isActiveItemSms = (data) => {
  return client.request({
    url: api.isActiveItemSms,
    data,
    method: "POST",
  });
};

export const isActiveItemMail = (data) => {
  return client.request({
    url: api.isActiveItemMail,
    data,
    method: "POST",
  });
};

export const getUpdateNotificationMailValidCode = (params) => {
  return client.request({
    url: api.getUpdateNotificationMailValidCode,
    params,
    method: "GET",
  });
};

export const updateShopNotificationConfigMail = (data) => {
  return client.request({
    url: api.updateShopNotificationConfigMail,
    data,
    method: "POST",
  });
};

export const getUpdateUserMailValidCode = (params) => {
  return client.request({
    url: api.getUpdateUserMailValidCode,
    params,
    method: "GET",
  });
};

export const getUpdateUserMailSmsValidCode = (params) => {
  return client.request({
    url: api.getUpdateUserMailSmsValidCode,
    params,
    method: "GET",
  });
};

export const checkUpdateUserMailSmsValidCode = (data) => {
  return client.request({
    url: api.checkUpdateUserMailSmsValidCode,
    data,
    method: "POST",
  });
};

export const updateAccountMail = (data) => {
  return client.request({
    url: api.updateAccountMail,
    data,
    method: "POST",
  });
};