<template>
  <a-modal
    v-model:visible="state.visible"
    :centered="true"
    :mask-closable="false"
    :confirm-loading="state.confirmLoading"
    :auto-complete="false"
    @cancel="close"
    @ok="handleConfirm"
  >
    <template #title>
      <span v-if="props.mailType === 1">{{ $t('user.configure_email') }}</span>
      <span v-else-if="props.mailType === 2">{{ $t('warehouse.second_step') }}: {{ $t('user.configure_email') }}</span>
    </template>
    <a-form
      ref="formRef"
      :model="state.formState"
      :rules="rules"
      :label-col="{ style: { width: '150px'} }"
      :wrapper-col="{ span: 14 }"
    >
      <a-form-item :label="$t('public.email')" name="mail">
        <a-input v-model:value="state.formState.mail" />
      </a-form-item>
      <a-form-item :label="$t('account.validation_code')" name="validCode">
        <a-input v-model:value="state.formState.validCode" :maxlength="8">
          <template #suffix>
            <a-button
              size="small"
              type="link"
              :loading="state.codeLoading"
              :disabled="isSendCodeBtnDisabled"
              @click="handleSendCode()"
            >
            {{ state.countdown === state.defaultCountdown ? $t('user.send_email_verification_code') : state.countdown + 's' }}
            </a-button>
          </template>
        </a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { computed, defineComponent, nextTick, reactive, ref, watch } from 'vue';
import { Button, Form, Input, message, Modal } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';
import { isValidEmail } from '@/utils/general.js';
import {
  getUpdateNotificationMailValidCode,
  getUpdateUserMailValidCode,
  updateShopNotificationConfigMail,
  updateAccountMail,
} from '@/api/modules/setting/notice.js';

export default defineComponent({
  emits: ['update:visible', 'confirm'],
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AButton: Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 邮箱类型：1：通知邮箱（总邮箱）；2：安全邮箱
    mailType: {
      type: Number,
      default: 1
    },
    phoneValidCode: String,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const formRef = ref(null);

    const state = reactive({
      visible: false,
      codeLoading: false,
      confirmLoading: false,
      formState: {
        mail: null,
        validCode: null,
      },
      countdown: 60,
      defaultCountdown: 60,
      countdownTimer: null,
    })

    const validateMail = (rule, value) => {
      if (!value) {
        return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("public.email")]));
      }
      if (!isValidEmail(value)) {
        return Promise.reject(vueI18n.t("common.p0_format_error", [vueI18n.t("public.email")]));
      }
      return Promise.resolve();
    }

    const rules = {
      mail: {
        required: true,
        validator: validateMail,
      },
      validCode: {
        required: true,
        message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("account.validation_code")]),
      },
    }

    const isSendCodeBtnDisabled = computed(() => {
      if (state.countdown !== state.defaultCountdown) {
        return true;
      }
      if (!state.formState.mail || !isValidEmail(state.formState.mail)) {
        return true;
      }
      return false;
    })

    const handleSetCountdown = () => {
      if (state.countdown === 1 || !state.visible) {
        clearTimeout(state.countdownTimer);
        state.countdown = state.defaultCountdown;
        return;
      }
      --state.countdown;
      state.countdownTimer = setTimeout(handleSetCountdown, 1000);
    }

    const handleSendCode = async () => {
      try {
        await formRef.value.validate(['mail']);
        state.codeLoading = true;
        if (props.mailType === 1) {
          await getUpdateNotificationMailValidCode({ email: state.formState.mail });
        } else if (props.mailType === 2) {
          await getUpdateUserMailValidCode({ email: state.formState.mail });
        }
        message.success(vueI18n.t('user.email_sent_successfully'));
        handleSetCountdown();
      } catch (error) {
      } finally {
        state.codeLoading = false;
      }
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        state.confirmLoading = true;
        const data = Object.assign({}, state.formState);
        if (props.mailType === 1) {
          await updateShopNotificationConfigMail(data);
          message.success(vueI18n.t("user.notification_mailbox_modified_successfully"));
        } else if (props.mailType === 2) {
          data.phoneCheckValidCode = props.phoneValidCode;
          await updateAccountMail(data);
          message.success(vueI18n.t("user.secure_mailbox_modified_successfully"));
        }
        emit("confirm");
        close();
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const close = () => {
      emit('update:visible', false);
    }

    watch(() => props.visible, (visible) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
        if (visible) {
          nextTick(() => {
            formRef.value.resetFields();
          });
        }
      }
    }, { immediate: true })

    return {
      props,
      state,
      formRef,
      rules,
      isSendCodeBtnDisabled,
      handleSendCode,
      handleConfirm,
      close,
    }
  }
})
</script>

<style scoped>

</style>